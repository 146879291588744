<template>
  <b-field label="Task controls">
    <b-select
      v-model="selected"
      :loading="processing"
      :disabled="processing"
      placeholder="Select task action"
      expanded
    >
      <optgroup label="Set status:">
        <option
          v-for="status in statuses"
          :key="status.value"
          :value="[changeStatus, status.value]"
          :disabled="status.value === task.status"
          >{{ status.label }}</option
        >
      </optgroup>
      <optgroup label="Assign team:">
        <option :disabled="task.team === null" :value="[changeTeam, null]"
          >None</option
        >
        <option
          v-for="team in teams"
          :key="team.key"
          :value="[changeTeam, team.key]"
          :disabled="team.key === task.team"
          >{{ team.label }}</option
        >
      </optgroup>
      <optgroup label="Other:">
        <option :value="[manageParticipants]">Manage delegates access</option>
        <option :value="[manageAgents]">Manage agents</option>
        <option :value="[scheduleTask]">Schedule task</option>
        <option :value="[viewLogs, { taskId }]">View task logs</option>
        <option :value="[updatePriority]">Update priority</option>
        <option
          v-if="
            !$_.includes(task.agents, userId) ||
              this.$_.get(task, 'alphaAgent.id') !== userId
          "
          :value="[assignToSelf]"
          >Assign to self</option
        >
      </optgroup>
    </b-select>
  </b-field>
</template>

<script>
import { statuses, teams, priorities } from "@src/data/task";
import { doc, updateDoc } from "@firebase/firestore";
export default {
  name: "StaffTaskControls",
  props: {
    taskId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      processing: false,
      selected: null,
      teams
    };
  },
  computed: {
    task() {
      return this.$store.getters["tasks/task"](this.taskId) || {};
    },
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    statuses() {
      return this.$_.filter(
        this.$store.state.tasks.statuses,
        status => status.value !== statuses.SCHEDULED
      );
    },
    priorities() {
      return this.$_.toArray(priorities).map(i => ({
        priority: i,
        label: this.$_.capitalize(i.substr(2))
      }));
    }
  },
  watch: {
    async selected(action) {
      if (action === null) return;
      this.processing = true;
      if (this.$_.isFunction(action[0])) await action[0](action[1]);
      this.$nextTick(() => {
        this.selected = null;
        this.processing = false;
      });
    }
  },
  async created() {
    if (this.task.siteId) {
      await this.$store.dispatch("sites/setHostingInfo", {
        siteId: this.task.siteId
      });
    }
  },
  methods: {
    assignToSelf() {
      const agentIds = this.$_.union(this.task.agents, [this.userId]);
      return this.$store
        .dispatch(`tasks/updateAgents`, {
          taskId: this.task._id,
          agentIds: agentIds,
          alphaAgentId: this.userId
        })
        .then(result => {
          this.$toast.open({
            message: result.message,
            type: "is-success"
          });
        })
        .catch(error => {
          this.$toast.open({
            message: error.message,
            type: "is-danger"
          });
        });
    },
    changeTeam(team = null) {
      const taskRef = doc(this.$firestore, `tasks`, this.taskId);
      return updateDoc(taskRef, { team }).then(() => {
        this.$toast.open({
          message: `Team updated`
        });
      });
    },
    changeStatus(status) {
      return this.$store
        .dispatch("tasks/updateTask", {
          taskId: this.taskId,
          status
        })
        .then(result => {
          this.$toast.open({
            message: `${result.message}`,
            position: "is-bottom",
            queue: false
          });
        })
        .catch(error => {
          this.$toast.open({
            message: `${error.message}`,
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        });
    },
    manageParticipants() {
      const modal = this.$modal.open({
        component: () => import("@shared/tasks/_manageParticipantsModal"),
        parent: this,
        width: 540,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          taskId: this.taskId,
          siteId: this.task.siteId,
          resellerId: this.userId
        },
        events: {
          close: () => {
            modal.close();
          }
        }
      });
    },
    manageAgents() {
      const modal = this.$modal.open({
        component: () => import("@shared/tasks/agent/_agentsSelectModal"),
        parent: this,
        width: 540,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          taskId: this.taskId
        },
        events: {
          close: () => {
            modal.close();
          }
        }
      });
    },
    scheduleTask() {
      this.$modal.open({
        component: () => import("@shared/tasks/_scheduleControlModal"),
        parent: this,
        width: 600,
        hasModalCard: true,
        canCancel: [],
        props: {
          taskId: this.taskId,
          dateScheduled: this.task.dateScheduled
            ? this.task.dateScheduled.toDate()
            : new Date()
        }
      });
    },
    viewLogs(params) {
      this.$modal.open({
        component: () => import("@shared/logs/_logsModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: this.$_.merge({}, { adminContext: true }, params)
      });
    },
    updatePriority() {
      this.$modal.open({
        component: () => import("@shared/tasks/_taskPriorityModal.vue"),
        parent: this,
        width: 540,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: { taskId: this.taskId }
      });
    }
  }
};
</script>
